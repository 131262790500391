import React from "react";
import HomeHero from "../components/home/HomeHero";

export default function Home() {
  return (
    <>
      <HomeHero />
    </>
  );
}
