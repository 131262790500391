import React from "react";

import logo from "../../assets/images/logo/logo.png";

const HomeHero = () => {
  return (
    <section className="hero">
      <div className="container">
        <div className="large">
          <div className="hero-content-container">
            <div className="family farm-bg">
              <div className="centered">
                <div className="centered-inner">
                  <a href="/" className="brand">
                    <img
                      src={logo}
                      alt="The Yusuf Family logo"
                      className="header-logo auto"
                    />
                  </a>
                  <h1>THE YUSUF FAMILY</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
